<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3" v-if="plans.length > 0">
        <div class="col mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col">
                  <h5>Health Tracker Plans</h5>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <button v-for="plan in plans"
                          :key="plan.id"
                          @click="viewPlan(plan)"
                          class="btn btn-lg mb-2 text-left w-100 position-relative"
                          :class="!plan.active ? 'btn-light' : 'btn-outline-primary'">
                    <span class="badge badge-danger mr-3" v-if="plan.is_awaiting_response">New</span>
                    <small>{{ plan.title }}</small>
                    <small v-if="!plan.active" class="float-right font-weight-bold small">Disabled</small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" v-if="plans.length === 0">
        <div class="col mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body py-4 text-center">
              <i class="fad fa-info-circle fa-5x text-primary mb-2"></i>
              <h3>No Health Tracker plans found.</h3>
              <p>Health Tracker plans sent by your practitioner will show here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      busy: true,
      plans: [],
    };
  },
  computed: {
    user() {
      return this.$store.user;
    }
  },
  methods: {
    fetchPlans() {
      this.$axios
              .get(process.env.VUE_APP_API_URL + "/client/mymop")
              .then(({ data }) => {
                this.plans = data;
                if (this.plans.length === 1){
                  this.viewPlan(this.plans[0]);
                } else {
                  this.busy = false;
                }
              });
    },
    viewPlan(plan) {
      this.$router.push("/tracker/plan/" + plan.id);
    },
  },
  mounted() {
    this.fetchPlans();
  }
};
</script>

<style>
</style>
